import "./JobsPage.scss"
import { useState, useEffect } from "react"
import { loaderIcon } from "../../service/image"
import { getData } from "../../service/service"
import JobItem from "./JobItem"

const JobsPage = () => {

	const [jobList, setJobList] = useState(null)

	useEffect(() => {
		getData("jobs").then((jobs) => {
			const filteredJobs = jobs.filter(job => job.acf.isActive)
			setJobList(filteredJobs)
		}).catch(() => {
			setJobList(false)
		})
	}, [])

	const renderContent = () => {
		if (jobList === null) {
			return <img style={{ display: "block", width: "40px", margin: "80px auto 0 auto" }} src={loaderIcon} alt="Загрузка..." />
		}
		if (jobList === false || jobList?.length < 1) {
			return <h2>Пока нету активных Вакансий</h2>
		}
		return (
			<div className="jobs__wrapper">
				<h2>Вакансии</h2>
				<div className="jobs__list row">
					{jobList.map((job) => <JobItem job={job} key={job.id} />)}
				</div>
			</div>
		)
	}

	return (
		<main id="jobs-page">
			<section className="jobs">
				<div className="container">
					{
						renderContent()
					}
				</div>
			</section>
		</main>
	)
}

export default JobsPage