import "./ProductCard.scss"
import { Link } from "react-router-dom"

const ProductCard = ({ product }) => {
	const { photo, name, sklad } = product.acf

	return (
		<Link to={`/products/${product.id}`} className="product-card">
			<img src={photo} alt="prod" />
			<h3>{name}</h3>
			<p>{sklad}</p>
		</Link>
	)

}

export default ProductCard