import "./Header.scss";
import { Link, NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import Burger from "../Burger/Burger";

const Header = () => {
	const [isActiveMenu, setActiveMenu] = useState(false)
	const location = useLocation()

	let touchStartX = 0;
	let touchEndX = 0;

	const handlerBurger = () => {
		setActiveMenu(prev => !prev)
	}

	const handleTouchStart = (e) => {
		touchStartX = e.touches[0].clientX;
	};

	const handleTouchEnd = (e) => {
		touchEndX = e.changedTouches[0].clientX;
		handleSwipeGesture();
	};

	const handleSwipeGesture = () => {
		if (touchStartX - touchEndX > 70) {
			setActiveMenu(false);
		}
	};

	useEffect(() => {
		const nav = document.querySelector("nav");
		nav.addEventListener("touchstart", handleTouchStart);
		nav.addEventListener("touchend", handleTouchEnd);

		return () => {
			nav.removeEventListener("touchstart", handleTouchStart);
			nav.removeEventListener("touchend", handleTouchEnd);
		};
	}, []);

	return (
		<header className={`${location.pathname === "/" ? "" : "header-special"}`}>
			<div className="container">
				<div className="header__wrapper row">
					<Link to={"/"} className="logo" onClick={() => setActiveMenu(false)}>
						Cдобушка
					</Link>

					<nav className={`${isActiveMenu ? "active" : ""}`}>
						<ul className="row">
							<li>
								<NavLink
									to="/"
									onClick={() => setActiveMenu(false)}
									className={({ isActive }) => isActive ? "nav-link active" : "nav-link"}
								>
									Главная
								</NavLink>
							</li>
							<li>
								<NavLink
									to="/products"
									onClick={() => setActiveMenu(false)}
									className={({ isActive }) => isActive ? "nav-link active" : "nav-link"}
								>
									Продукция
								</NavLink>
							</li>
							<li>
								<NavLink
									to="/jobs"
									onClick={() => setActiveMenu(false)}
									className={({ isActive }) => isActive ? "nav-link active" : "nav-link"}
								>
									Вакансии
								</NavLink>
							</li>
							<li>
								<NavLink
									to="/contacts"
									onClick={() => setActiveMenu(false)}
									className={({ isActive }) => isActive ? "nav-link active" : "nav-link"}
								>
									Контакты
								</NavLink>
							</li>
						</ul>
					</nav>

					<Burger isActive={isActiveMenu} handler={handlerBurger} />
				</div>
			</div>
		</header>
	);
}

export default Header;
