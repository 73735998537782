import "./App.scss"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { useState, useEffect } from "react"
import { getData } from "../../service/service"

import Header from "../Header/Header"
import HomePage from "../../pages/HomePage/HomePage"
import ProductsPage from "../../pages/ProductsPage/ProductsPage"
import ProductPage from "../../pages/ProductPage/ProductPage"
import JobsPage from "../../pages/JobsPage/JobsPage"
import JobPage from "../../pages/JobPage/JobPage"
import ContactPage from "../../pages/ContactPage/ContactPage"
import FixedCall from "../FixedCall/FixedCall"
import Footer from "../Footer/Footer"

const App = () => {
	const [products, setProducts] = useState(null)
	const [sliderProducts, setSliderProducts] = useState(null)

	useEffect(() => {
		getData("products").then((products) => {
			const fiteredToSlider = products.filter(prod => prod.acf.inSlider)
			setProducts(products)
			setSliderProducts(fiteredToSlider)
		}).catch(() => {
			setProducts(false)
			setSliderProducts(false)
		})
	}, [])

	return (
		<BrowserRouter>
			<Header />
			<Routes>
				<Route path="/" element={<HomePage sliderProducts={sliderProducts} />} />
				<Route path="/products" element={<ProductsPage products={products} />} />
				<Route path="/products/:id" element={<ProductPage products={products} />} />
				<Route path="/jobs" element={<JobsPage />} />
				<Route path="/jobs/:id" element={<JobPage />} />
				<Route path="/contacts" element={<ContactPage />} />
			</Routes>
			<Footer />
			<FixedCall />
		</BrowserRouter>
	)
}

export default App