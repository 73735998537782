import 'swiper/css'
import 'swiper/css/pagination'
import "./ProductSlider.scss"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { loaderIcon } from '../../service/image';
import { Link } from 'react-router-dom';
import ProductCard from '../ProductCard/ProductCard';

const ProductSlider = ({ title, products }) => {

	function renderContent() {
		if (products === null) {
			return <img style={{ display: "block", width: "40px", margin: "80px auto 0 auto" }} src={loaderIcon} alt="Загрузка..." />
		}
		if (products === false) {
			return <h2>Слайдер временно не доступен...</h2>
		}

		return (
			<>
				{title && <h2>{title}</h2>}
				<Swiper
					autoHeight={true}
					slidesPerView={1}
					spaceBetween={20}
					pagination={{
						clickable: true,
					}}
					breakpoints={{
						580: {
							slidesPerView: 2,
						},
						880: {
							slidesPerView: 3,
						},
						1180: {
							slidesPerView: 4,
						},
					}}
					modules={[Pagination]}
					className="mySwiper"
				>
					{
						products.map(prod => {
							return (
								<SwiperSlide key={prod.id}>
									<ProductCard product={prod} />
								</SwiperSlide>
							)
						})
					}
				</Swiper>
				<Link to={"/products"} className="nav-link">Смотреть всю продукцию &#8594;</Link>
			</>
		)
	}

	return (
		<section className="prod-slider">
			<div className="container">
				<div className="prod-slider__wrapper">
					{renderContent()}
				</div>
			</div>
		</section>
	)
}

export default ProductSlider