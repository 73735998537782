import axios from "axios"

async function getData(route) {
	const _WP_URL = `https://admin.sdobushka-bakery.ru/wp-json/wp/v2/${route}?acf_format=standard&_fields=id,acf&_embed&per_page=100`;

	try {
		let res = await axios.get(_WP_URL);
		return res.data;
	} catch (error) {
		throw error;
	}
}

async function getDataById(route, id) {
	const _WP_URL = `https://admin.sdobushka-bakery.ru/wp-json/wp/v2/${route}/${id}?acf_format=standard&_fields=id,acf&_embed`;

	try {
		let res = await axios.get(_WP_URL);
		return res.data;
	} catch (error) {
		throw error;
	}
}

export { getData, getDataById }

