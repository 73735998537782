import { aboutImage } from "../../service/image"

const About = () => {

	return (
		<section className="about">
			<div className="container">
				<div className="about__wrapper row">
					<div className="about__image">
						<img src={aboutImage} alt="about-image" />
					</div>

					<div className="about__content">
						<h2>О нас</h2>
						<p>Наша пекарня специализируется на выпекании хлеба, батонов и булок, соблюдая самые высокие стандарты качества. Мы используем только натуральные ингредиенты для обеспечения свежей и вкусной выпечки каждый день.</p>
						<p>Каждый наш продукт проходит тщательный контроль качества на всех этапах производства. Выпекаем с любовью и вниманием к деталям, чтобы дарить вам аромат домашнего хлеба. Посетите нашу пекарню и почувствуйте настоящий вкус натуральной выпечки!</p>
					</div>
				</div>
			</div>
		</section>
	)
}

export default About