import { Link } from "react-router-dom"

const Hero = () => {

	return (
		<section className="hero">
			<div className="container">
				<div className="hero__wrapper row">
					<h1>Секрет нашей пекарни это натуральные продукты и забота</h1>
					<Link to="/products" className="btn-white">Просмотреть продукцию</Link>
				</div>
			</div>
		</section>
	)
}

export default Hero