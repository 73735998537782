import "./Burger.scss"

const Burger = ({ isActive, handler }) => {

	return (
		<button className={`burger ${isActive ? "active" : ""}`} onClick={handler}>
			<figure></figure>
			<figure></figure>
			<figure></figure>
		</button>
	)
}

export default Burger

