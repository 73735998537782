import "./ProductsPage.scss"
import { useState, useEffect } from "react"
import { loaderIcon } from "../../service/image"
import { Select, MenuItem, InputLabel, Box, FormControl } from "@mui/material"
import ProductCard from "../../components/ProductCard/ProductCard"


const ProductsPage = ({ products }) => {
	const [prodFilter, setProdFilter] = useState("all")
	const [viewProds, setViewProds] = useState(null)

	useEffect(() => {
		if (products === null) return
		if (!products) return setViewProds(false)
		filteredProducts(products)
	}, [products, prodFilter])

	const handleChangeSelect = (event) => {
		setProdFilter(event.target.value);
	};

	const filteredProducts = (prods) => {
		if (prodFilter === "all") return setViewProds(prods)
		let filteredProds = prods.filter(prod => prod.acf.category === prodFilter)
		setViewProds(filteredProds)
	}

	function renderContent() {
		if (viewProds === null) {
			return <img style={{ display: "block", width: "40px", margin: "80px auto 0 auto" }} src={loaderIcon} alt="Загрузка..." />
		}
		if (viewProds === false) {
			return <h2>Каталог продуктов временно не доступен...</h2>
		}
		return (
			<>
				<div className="products__top">
					<h2>Продукция</h2>
					<Box sx={{ minWidth: 120, cursor: "pointer" }}>
						<FormControl fullWidth>
							<InputLabel sx={{
								'&.Mui-focused': {
									color: '#25120E',
								},
							}} id="demo-simple-select-label">Категория</InputLabel>
							<Select
								id="simple-select"
								labelId="demo-simple-select-label"
								value={prodFilter}
								label="Категория"
								onChange={handleChangeSelect}
								sx={{
									width: "180px",
									height: "42px",
									'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
										borderColor: '#25120E',
									},
									'&:hover .MuiOutlinedInput-notchedOutline': {
										borderColor: '#59342C',
									},
									'.MuiSvgIcon-root': {
										color: '#25120E',
									},
								}}
							>
								<MenuItem value={"all"}>Вся продукция</MenuItem>
								<MenuItem value={"Хлеб"}>Хлеб</MenuItem>
								<MenuItem value={"Батоны и багеты"}>Батоны и багеты</MenuItem>
								<MenuItem value={"Сладкие изделия"}>Сладкие изделия</MenuItem>
							</Select>
						</FormControl>
					</Box>
				</div>
				<div className="products__list">
					{
						viewProds.map(prod => {
							return (
								<ProductCard product={prod} key={prod.id} />
							)
						})
					}
				</div>
			</>
		)
	}

	return (
		<main id="products-page">
			<section className="products">
				<div className="container">
					<div className="products__wrapper">
						{renderContent()}
					</div>
				</div>
			</section>
		</main>
	)
}

export default ProductsPage