import { useEffect } from "react";
import "./Modal.scss";

const Modal = ({ isOpen, closeHandler, content }) => {
	useEffect(() => {
		if (isOpen) {
			const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
			document.body.style.overflow = "hidden";
			document.body.style.paddingRight = `${scrollBarWidth}px`;
		} else {
			document.body.style.overflow = "";
			document.body.style.paddingRight = "";
		}

		return () => {
			document.body.style.overflow = "";
			document.body.style.paddingRight = "";
		};
	}, [isOpen]);

	if (!isOpen) return null;

	const handleBackgroundClick = (e) => {
		if (e.target.classList.contains("modal")) {
			closeHandler();
		}
	};

	return (
		<div id="modal-window" className="modal" onClick={handleBackgroundClick}>
			<div className="modal__window">
				{content}
				<button className="modal__window-close" onClick={closeHandler}>✕</button>
			</div>
		</div>
	);
};

export default Modal;
