import "./ContactPage.scss"

const ContactPage = () => {

	return (
		<main id="contact-page">
			<section className="contacts">
				<div className="container">
					<div className="contacts__wrapper">
						<div className="contacts__content">
							<h2>Наши контактные данные</h2>
							<a href="tel:+79493294966">Тел. +7949-329-49-66</a>
							<a href="tel:+79494858610">Тел. +7949-485-86-10</a>
							<a target="_blank" href="https://yandex.eu/maps/-/CDXk4P-L"><span>Адрес:</span>286135, Донецк, город Макеевка, улица Калинина, 92 Б</a>
							<p><span>Инидивидуальный предприниматель:</span> <br /> Мынка Виталий Викторович</p>
						</div>
						<div className="contacts__map">
							<iframe src="https://yandex.com/map-widget/v1/?ll=37.973934%2C48.059225&mode=whatshere&whatshere%5Bpoint%5D=37.973934%2C48.059225&whatshere%5Bzoom%5D=17&z=16" frameborder="1" allowfullscreen="true"></iframe>
						</div>
					</div>
				</div>
			</section>
		</main>
	)
}

export default ContactPage