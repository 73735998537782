import { Link } from "react-router-dom"
import { rublIcon, locationIcon } from "../../service/image"

const JobItem = ({ job }) => {
	const { jobtitle, sallary, location, isActive, visibleDate, shortDescr } = job.acf

	return (
		<Link to={`/jobs/${job.id}`} className="job-item">
			<div className="job-item__title row">
				<h3>{jobtitle}</h3>
				<figure className={`job-item__status ${isActive ? "active" : ""}`}>{isActive ? "Ваканция активна" : "Ваканция не активна"}</figure>
			</div>

			<div className="job-item__price row">
				<img src={rublIcon} alt="rublIcon" />
				<span>{sallary}</span>
			</div>

			<div className="job-item__location row">
				<img src={locationIcon} alt="locationIcon" />
				<span>{location}</span>
			</div>

			<p className="job-item__descr">{shortDescr}</p>

			<div className="job-item__date row">
				<button className="link-more">Подробнее</button>
				<span>{visibleDate}</span>
			</div>
		</Link>
	)
}

export default JobItem