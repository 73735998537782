import "./ProductPage.scss"
import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { loaderIcon } from "../../service/image"
import { Link } from "react-router-dom"
import ProductSlider from "../../components/ProductSlider/ProductSlider"

const ProductPage = ({ products }) => {
	const [product, setProduct] = useState(null)
	const { id } = useParams()

	useEffect(() => {

		if (products === null) return

		if (!products) return setProduct(false)

		let findedProd = products.find(el => el.id === +id)

		if (findedProd) {
			setProduct(findedProd)
		} else {
			setProduct(false)
		}
	}, [id, products])

	const renderContent = () => {
		if (product === null) {
			return <img style={{ display: "block", width: "40px", margin: "80px auto 0 auto" }} src={loaderIcon} alt="Загрузка..." />
		}
		if (product === false) {
			return <h2>Такого продукта не существует</h2>
		}

		const { name, sklad, photo, weight, energy, timeRelize, timeLife } = product.acf

		return (
			<div className="product__info">
				<div className="product__info-image">
					<img src={photo} alt="prod" />
				</div>
				<div className="product__info-content">
					<h2>{name}</h2>
					<h3>Состав</h3>
					<p>{sklad}</p>
					<h3>Характеристики</h3>
					<ul>
						<li>Вес: <span>{weight}</span></li>
						<li>Энергетическая ценность: <span>{energy}</span></li>
						<li>Срок реализации неупакованных изделий: <span>{timeRelize}</span></li>
						<li>Срок годности упакованных изделий: <span>{timeLife}</span></li>
						<li>Производство: <span>собственноe, хлеб выпекается ежедневно</span></li>
					</ul>
				</div>
			</div>
		)
	}

	return (
		<main id="product-page">
			<section className="product">
				<div className="container">
					<div className="product__wrapper">
						<Link to={"/products"} className="link-label">&#8592; К Продуктам</Link>
						{renderContent()}
					</div>
				</div>
			</section>
			<ProductSlider products={products} title={"Больше продуктов"} />
		</main>
	)
}

export default ProductPage