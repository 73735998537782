import "./HomePage.scss"

import Hero from "./Hero"
import About from "./About"
import Advantages from "./Advantages"
import ProductSlider from "../../components/ProductSlider/ProductSlider"

const HomePage = ({ sliderProducts }) => {
	return (
		<main id="home-page">
			<Hero />
			<ProductSlider title={"Наша продукция"} products={sliderProducts} />
			<About />
			<Advantages />
		</main>
	)
}

export default HomePage