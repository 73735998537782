import "./JobPage.scss"
import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { loaderIcon } from "../../service/image"
import { getDataById } from "../../service/service"
import { Link } from "react-router-dom"
import { rublIcon, locationIcon } from "../../service/image"
import Modal from "../../components/Modal/Modal"
import Form from "../../components/Form/Form"

const JobPage = () => {

	const [job, setJob] = useState(null)
	const [activeModal, setActiveModal] = useState(false)
	const { id } = useParams()

	useEffect(() => {
		getDataById("jobs", id).then((data) => {
			if (data.message || !data.acf?.isActive) {
				setJob(false)
			} else {
				setJob(data)
			}
		}).catch(() => {
			setJob(false)
		})
	}, [id])

	const closeModal = () => {
		setActiveModal(false)
	}

	const renderContent = () => {
		if (job === null) {
			return <img style={{ display: "block", width: "40px", margin: "80px auto 0 auto" }} src={loaderIcon} alt="Загрузка..." />
		}
		if (job === false) {
			return <h2>Такой вакансии не существует</h2>
		}
		return (
			<div className="job__info">
				<h2>{job.acf.jobtitle}</h2>

				<div className="job__price row">
					<img src={rublIcon} alt="rublIcon" />
					<span>{job.acf.sallary}</span>
				</div>

				<div className="job__location row">
					<img src={locationIcon} alt="locationIcon" />
					<span>{job.acf.location}</span>
				</div>

				<h3>Описание вакансии</h3>

				<div className="job__descr" dangerouslySetInnerHTML={{ __html: job.acf.jobdescr }} />

				<div className="job__date row">
					<button onClick={() => setActiveModal(true)} className="link-more">Откликнуться</button>
					<span>{job.acf.visibleDate}</span>
				</div>
			</div>
		)
	}


	return (
		<main id="job-page">
			<section className="job">
				<div className="container">
					<div className="job__wrapper">
						<Link to={"/jobs"} className="link-label">&#8592; К вакансиям</Link>
						{renderContent()}
					</div>
				</div>
			</section>
			<Modal isOpen={activeModal} closeHandler={closeModal} content={<Form />} />
		</main>
	)
}

export default JobPage