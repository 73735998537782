import { adv1Icon, adv2Icon, adv3Icon, adv4Icon } from "../../service/image"

const Advantages = () => {

	return (
		<section className="advantages">
			<div className="container">
				<div className="advantages__wrapper">
					<h2>Наши преимущества</h2>
					<div className="advantages__list row">
						<div className="advantages__card">
							<img src={adv1Icon} alt="card" />
							<p>Высокие стандарты качества</p>
						</div>

						<div className="advantages__card">
							<img src={adv2Icon} alt="card" />
							<p>Натуральные ингредиенты</p>
						</div>

						<div className="advantages__card">
							<img src={adv3Icon} alt="card" />
							<p>Доступные цены</p>
						</div>

						<div className="advantages__card">
							<img src={adv4Icon} alt="card" />
							<p>Быстрая доставка</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Advantages