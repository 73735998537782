import "./Footer.scss"

const Footer = () => {

	return (
		<footer>
			<div className="container">
				<div className="footer__wrapper row">
					<div className="footer__wrapper-info">
						<p>Пекарня “Сдобушка”</p>
					</div>

					<div className="footer__wrapper-contacts">
						<a href="tel:+79493294966">Тел. +7949-329-49-66</a>
						<a href="tel:+79494858610">Тел. +7949-485-86-10</a>
						<a target="_blank" href="https://yandex.eu/maps/-/CDXk4P-L">Адрес: 286135, Донецк, город Макеевка, улица Калинина, 92 Б</a>
						<p>Инидивидуальный предприниматель: Мынка Виталий Викторович</p>
					</div>
				</div>
			</div>
		</footer>
	)
}

export default Footer