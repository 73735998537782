import "./FixedCall.scss"

import { phoneImage } from "../../service/image"

const FixedCall = () => {

	return (
		<div className="fixed-call">
			<a href="tel+"><img src={phoneImage} alt="Call" /></a>
		</div>
	)
}

export default FixedCall