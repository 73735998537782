import { useState } from 'react';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import "./Form.scss";

const Form = ({ t }) => {

	const [message, setMessage] = useState("")

	const initialValues = {
		name: '',
		phone: '',
		message: ''
	};

	const validationSchema = Yup.object({
		name: Yup.string()
			.required('Поле обязательно для заполнения')
			.min(3, 'Имя должно содержать минимум 3 символа'),
		phone: Yup.string()
			.matches(/^[0-9]+$/, 'Поле телефон должно содержать только цифры')
			.min(10, 'Телефон должен содержать минимум 10 цифр')
			.max(15, 'Телефон должен содержать максимум 15 цифр')
			.required('Поле телефон обязательно для заполнения')
	});

	const onSubmit = (values, { setSubmitting, resetForm }) => {

		const formData = new FormData();
		formData.append('name', values.name);
		formData.append('phone', values.phone);
		formData.append('message', values.message || 'Отсутствует');

		fetch('/form.php', {
			method: 'POST',
			body: formData
		})
			.then(response => {
				if (response.ok) {
					setMessage('Сообщение успешно отправлено!');
					resetForm();
				} else {
					setMessage('Произошла ошибка при отправке сообщения.');
				}
				setSubmitting(false);
			})
			.catch(() => {
				setMessage('Произошла ошибка при отправке сообщения.');
				setSubmitting(false);
			});

		setTimeout(() => {
			setSubmitting(false);
			resetForm();
			setMessage("")
		}, 2000);
	};

	return (
		<div id="form-loyaut" className="form">
			<h2>Оставьте заявку и мы с Вами свяжемся</h2>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
			>
				{({ isSubmitting }) => (
					<FormikForm>
						<div>
							<Field className="inp" type="text" name="name" placeholder="Ваше имя и фамилия" />
							<ErrorMessage name="name" component="div" className="error" />
						</div>

						<div>
							<Field className="inp" type="tel" name="phone" placeholder="Ваш номер телефона" />
							<ErrorMessage name="phone" component="div" className="error" />
						</div>

						<div>
							<Field className="inp" as="textarea" name="message" placeholder="Сообщение" />
						</div>

						{message && <p>{message}</p>}

						<button type="submit" disabled={isSubmitting}>
							{isSubmitting ? "Отправка..." : "Отправить"}
						</button>
					</FormikForm>
				)}
			</Formik>
		</div>
	);
};

export default Form;